const shortMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

function getPrettyDate(dateString) {
  const date = new Date(dateString)

  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const month = shortMonthNames[monthIndex]
  const day = date.getDate()

  return `${day} ${month} ${year}`
}

function getPrettyYear(dateString) {
  const date = new Date(dateString)

  const year = date.getFullYear()
  
  return `${year}`
}

function getStandardDate(dateString) {
  const date = new Date(dateString)
  const formatted = date.toISOString().slice(0, 10)

  return formatted
}

export { getPrettyDate, getStandardDate, getPrettyYear }
