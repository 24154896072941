import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import SectionHead from "../components/newComponents/sectionHead";
import EditLink from "../components/newComponents/editLink";
import SocialMediaButtons from "../components/newComponents/socialShare";
import ImgixFilePreview from "../components/newComponents/ImgixFilePreview";
import { getPrettyDate } from "../utils/dates";
import { CgSoftwareDownload } from 'react-icons/cg';
import Matrix from "../components/matrix";

export const query = graphql`
  query ResourceEntryQuery($slug: String!) {
    entry: craftResourcesResourcesEntry(slug: { eq: $slug }) {
      id
      remoteId
      sectionHandle
      title
      postDate
      url
      uri
      summary
      bodyContent {
        ...BodyContentFragment
      }
      featureImage {
        ...AssetFragment
      }
      file {
        url
        title
        size
        height
        kind
        width
      }
    }

    site: site {
      siteMetadata {
        url
      }
    }
  }
`;

const ResourceEntry = ({ data: { entry, site }, pageContext }) => {

  const file = entry.file[0];

  return (
    <Layout pageContext={pageContext}>
      <Seo title={entry.title} image={entry.featureImage[0]?.url} />
      <div className="main">
        <section className="section-inside" style={{ padding: "0" }}>
          <div className="shell">
            <SectionHead heading={entry.title} image={entry.featureImage[0] || null}/>
            <div className="section__inner">
              <aside className="section__aside">
                <ul className="list-info">
                  <li>
                    <p className="list__title"><strong>Date posted</strong></p>
                    <p className="list__details">{getPrettyDate(entry.postDate)}</p>
                  </li>
                  {file && file.title && <li>
                    <p className="list__title"><strong>File name</strong></p>
                    <p className="list__details">{file.title}</p>
                  </li>}
                  {file && file.kind && 
                    <li>
                      <p className="list__title"><strong>File type</strong></p>
                      <p className="list__details uppercase">{file.kind}</p>
                    </li>
                  }
                  
                  {file && file.url && 
                    <li>
                      <p className="list__title"><strong>Cover</strong></p>
                      <div className="list__details pr-2 relative">
                        <a href={file.url} target="_blank" rel="noreferrer">
                          <ImgixFilePreview fit="fill" fileUrl={file.url} className="mt-2 border-gray-100 border-solid border" square={true} height={500}/>
                          <span className="absolute  top-0 left-0 w-full h-full flex justify-center items-center">
                            <CgSoftwareDownload className="w-8 h-8 text-white" style={{filter: "drop-shadow( 3px 3px 5px rgba(0, 0, 0, .9))"}} alt=""/>
                          </span>
                        </a>
                      </div>
                    </li>
                  }
                </ul>             
              </aside>
              <div className="section__content">
                <div dangerouslySetInnerHTML={{ __html: entry.summary }} className="prose max-w-none mb-8 body-content-rich-text-bullets"/>
                <Matrix blocks={entry.bodyContent} />
                {file && file.url && <a href={file.url} className="btn btn--green" target="_blank" rel="noreferrer" download>Download</a>}
                <SocialMediaButtons url={`${site.siteMetadata.url}/${entry.uri}`} title={`${entry.title}`} summary={entry.summary} twitterVia="GenesisEconomic" linkedInSource="Genesis Analytics"/>
                <EditLink sectionHandle={entry.sectionHandle} remoteId={entry.remoteId}/>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ResourceEntry
